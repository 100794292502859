import React, { createContext, useState, useEffect } from 'react'

export const BlogDataContext = createContext()

export const BlogDataProvider = ({ children }) => {
  const [blogeData, setBlogeData] = useState(null)

  const baseUrl = process.env.REACT_APP_API_BASE_URL

  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch(`${baseUrl}blog-list`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        setBlogeData(result)
      })
      .catch(error => console.log('error', error))
  }, [])
  return (
    <BlogDataContext.Provider value={{ blogeData, setBlogeData }}>
      {children}
    </BlogDataContext.Provider>
  )
}
