import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaAngleDoubleRight } from "react-icons/fa";
const Matchcoverage = ({ deviceType }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <>
      <section className="ptb">
        <div className="match-cover01">
          <div className="container ">
            <div className="cover">
              <h2 className="main-hed01">Match Coverage</h2>
              <a href="#" className="btn btn-asee d-none d-md-inline-block">
                See All <FaAngleDoubleRight style={{ fontSize: "20px" }} />
              </a>
            </div>
            <Carousel
              responsive={responsive}
              swipeable={false}
              draggable={false}
              ssr={true}
              infinite={true}
              infiniteLoop={true}
              autoPlay={deviceType !== "mobile" ? true : false}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              customTransition="all .5s"
              transitionDuration={1500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              <div className="slider">
                {" "}
                <a href="#" className="items-cover0 d-inline-block w-100 ml-3">
                  <div className="imghj">
                    <img alt="ty" src="images/31webstersilk.webp" />
                  </div>
                  <div className="details-text">
                    <h5>Jewell-led beat Bulls despite McDermott heroics</h5>
                    <p>
                      7h ago <span> Devid Warner </span>
                    </p>
                  </div>
                </a>
              </div>
              <div className="slider">
                {" "}
                <a href="#" className="items-cover0 d-inline-block w-100">
                  <div className="imghj">
                    <img alt="ty" src="images/team-bcci.jpg" />
                  </div>
                  <div className="details-text">
                    <h5>LIVE: Openers tee off for India in West</h5>
                    <p>
                      7h ago <span> Devid Warner </span>
                    </p>
                  </div>
                </a>
              </div>
              <div className="slider">
                <a href="#" className="items-cover0 d-inline-block w-100">
                  <div className="imghj">
                    <img alt="ty" src="images/blo4.webp" />
                  </div>
                  <div className="details-text">
                    <h5>Jewell-led beat Bulls despite McDermott heroics</h5>
                    <p>
                      7h ago <span> Devid Warner </span>
                    </p>
                  </div>
                </a>
              </div>
              <div className="slider">
                {" "}
                <a href="#" className="items-cover0 d-inline-block w-100">
                  <div className="imghj">
                    <img alt="ty" src="images/team-bcci.jpg" />
                  </div>
                  <div className="details-text">
                    <h5>LIVE: Openers tee off for India in West</h5>
                    <p>
                      7h ago <span> Devid Warner </span>
                    </p>
                  </div>
                </a>
              </div>
              {/* <div>Item 4</div> */}
            </Carousel>
            {/* <a href="#" className="btn btn-asee d-table d-md-none mx-auto mt-4">
            See All <i className="fas fa-angle-right" />
          </a> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Matchcoverage;
