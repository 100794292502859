import React from 'react'

const Loader = () => {
  return (
    <>
      <img src='https://via.placeholder.com/400x250?text=Loading...' />
    </>
  )
}

export default Loader
