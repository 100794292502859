import React, { createContext, useState, useEffect } from 'react'

export const GetNativeSponsersContext = createContext()

export const GetNativeSponsersProvider = ({ children }) => {
  const [nativeBannerData, setNativeBannerData] = useState(null)

  const baseUrl = process.env.REACT_APP_API_BASE_URL

  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch(
      'https://api.cricchamp.in/apiPublic/getNativeSponsers',
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setNativeBannerData(result?.data)
      })
      .catch(error => console.log('error', error))
  }, [])
  return (
    <GetNativeSponsersContext.Provider
      value={{ nativeBannerData, setNativeBannerData }}
    >
      {children}
    </GetNativeSponsersContext.Provider>
  )
}
