import React, { useContext, useState } from 'react'
import { FaHome } from 'react-icons/fa'
import { IoMailOpenOutline } from 'react-icons/io5'
import { MdOutlinePhoneAndroid } from 'react-icons/md'
import { SettingsContext } from './SettingsContext'
import { ToastContainer, toast } from 'react-toastify'

const Contact = () => {
  const { settingsData } = useContext(SettingsContext)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  const socialLinksMap = {}
  if (settingsData && settingsData.length > 0) {
    settingsData.forEach(item => {
      socialLinksMap[item.id] = item.value
    })
  }
  // ===============Contact Us form======================
  const ContactSubmit = e => {
    e.preventDefault()
    var formdata = new FormData()
    formdata.append('email', email)
    formdata.append('name', name)
    formdata.append('message', message)

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseUrl}user-contact`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status) {
          toast.success(result.message, {
            autoClose: 1000
          })
          setEmail('')
          setName('')
          setMessage('')
        } else {
          toast.error(result.message, {
            autoClose: 2000
          })
        }
      })
      .catch(error => console.log('error', error))
  }
  return (
    <>
      <ToastContainer />

      <section class='banner-part sub-main-banner float-start w-100'>
        <div class='baner-imghi'>
          <img
            src='https://oxentictemplates.in/templatemonster/cricket-new/images/sub-banner01.jpg'
            alt='sub-banner'
          />
        </div>

        <div class='sub-banner'>
          <div class='container'>
            <h1 class='text-center'>Contact Us</h1>
            <nav aria-label='breadcrumb'>
              <ol class='breadcrumb justify-content-center'>
                <li class='breadcrumb-item'>
                  <a href='#'>Home</a>
                </li>
                <li class='breadcrumb-item active' aria-current='page'>
                  Contact Us
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <div className='about-page-main comon-sub-page-main d-inline-block w-100'>
        <div className='about-club-top'>
          <div className='container'>
            <div className='row row-cols-1 row-cols-lg-2 g-lg-5'>
              <div className='col position-relative '>
                <figure className='big-img'>
                  <img
                    src='https://oxentictemplates.in/templatemonster/cricket-new/images/GettyImages-1764843770.webp'
                    alt='pic'
                  />
                </figure>
                <figure className='small-img'>
                  <img
                    src='https://oxentictemplates.in/templatemonster/cricket-new/images/ghj.jpeg'
                    alt='pic2'
                  />
                </figure>
              </div>
              <div className='col'>
                {/* <h5 className="samll-sub mb-1 mt-0"> Our Story </h5> */}
                <h2 className='comon-heading m-0'> Contact Us </h2>
                <p className='mt-3'>{socialLinksMap[18]}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CONTACT FORM */}

      <div className='about-page-main comon-sub-page-main d-inline-block w-100 contact_form mb-4'>
        <div className='about-club-top'>
          <div className='container'>
            <div className='row row-cols-1 row-cols-lg-2 g-lg-5'>
              <div className='leave-sec-part mt-5 mb-5'>
                <h2 className='comon-heading mt-0 mb-3'> Leave a Comment </h2>
                <form onSubmit={ContactSubmit}>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Full Name'
                          required
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Email'
                          required
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <textarea
                          className='form-control'
                          placeholder='Message'
                          defaultValue={''}
                          required
                          value={message}
                          onChange={e => setMessage(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <input
                          type='submit'
                          className='btn subimt-comment'
                          defaultValue='Post Comment'
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className='col'>
                {/* <h5 className="samll-sub mb-1 mt-0"> Our Story </h5> */}
                <h2 className='comon-heading m-0'> Contact Details </h2>
                <p className='mt-3'>
                  If you have any query, suggestion or feedback for us, please
                  feel free to contact us, we will be happy to hear.
                </p>
                <div className='Address'>
                  <h5>
                    <FaHome />
                  </h5>
                  <span>{socialLinksMap[19]}</span>
                </div>
                <div className='mail'>
                  <h5>
                    <IoMailOpenOutline />{' '}
                  </h5>
                  <span>{socialLinksMap[4]}</span>
                </div>
                <div className='phone'>
                  <h5>
                    <MdOutlinePhoneAndroid />
                  </h5>
                  <span>{socialLinksMap[5]}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
