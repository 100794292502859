import React, { useContext } from 'react'
import { CMSContext } from './CMSContext'

const Policy = () => {
  const { CMSData } = useContext(CMSContext)

  const privacyPolicy = CMSData?.find(page => page.slug === 'PrivacyPolicy')

  const Policydescription = privacyPolicy
    ? privacyPolicy.description
    : 'Privacy Policy not found.'

  const PolicyHeading = privacyPolicy
    ? privacyPolicy.title
    : 'Privacy Policy not found.'

  // Function to strip HTML tags from a string
  const stripHtmlTags = html => {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent || div.innerText || ''
  }

  return (
    <>
      <section className='banner-part sub-main-banner mb-2 float-start w-100'>
        <div className='baner-imghi'>
          <img
            src='https://oxentictemplates.in/templatemonster/cricket-new/images/sub-banner01.jpg'
            alt='sub-banner'
          />
        </div>
        <div className='sub-banner'>
          <div className='container'>
            <h1 className='text-center'>{PolicyHeading}</h1>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb justify-content-center'>
                <li className='breadcrumb-item'>
                  <a href='#'>Home</a>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  {PolicyHeading}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <div className='container'>
        <h5 className='comon-heading m-0'>{PolicyHeading}</h5>
        <p className='mt-2'> {stripHtmlTags(Policydescription)} </p>
      </div>
    </>
  )
}

export default Policy
