import React, { useState, useEffect, useContext } from 'react'
import { FaRegComment, FaRegUser, FaSadCry } from 'react-icons/fa'
import { LuCalendarDays } from 'react-icons/lu'
import { CiShare2 } from 'react-icons/ci'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import Loader from './Loader'
import { CategotyContext } from './CategotyContext'
import { BlogDataContext } from './BlogDataContext'
import { GetBannerSponsersContext } from './GetBannerSponsersContext'
import { LiveBlogContext } from './LiveBlogContext'
import { GetNativeSponsersContext } from './GetNativeSponsersContext'
const LiveBlogdetail = () => {
  const { categoryData } = useContext(CategotyContext)
  const { blogeData } = useContext(BlogDataContext)
  const { sponsersBannerData } = useContext(GetBannerSponsersContext)
  const { nativeBannerData } = useContext(GetNativeSponsersContext)
  const { LiveBlogData } = useContext(LiveBlogContext)

  const { slug } = useParams()
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  const imgurl = process.env.REACT_APP_API_IMG_URL

  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [articleDetails, setArticleDetails] = useState([])
  const [categoryList, setcategoryList] = useState([])
  const [load, setLoad] = useState(true);
  // ================single blog details================
  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }
    setTimeout(() => {

      fetch(`${baseUrl}blog-detail?slug=${slug}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setArticleDetails(result?.data)
          setLoading(false)
        })
        .catch(error => {
          console.log('error', error)
          setLoading(true)
        }).finally(() => {
          setLoad(!load)
        })
    }, 5000);
  }, [slug, load])

  // ==================page title==================
  const useDocumentTitle = title => {
    useEffect(() => {
      document.title = title
    }, [title])
  }
  useDocumentTitle(`  ${articleDetails?.title} ||  Cricket Live match blog`)

  return (
    <>
      {/* <section className='banner-part sub-main-banner float-start w-100'>
        <div className='baner-imghi'>
          <img
            src='https://oxentictemplates.in/templatemonster/cricket-new/images/sub-banner01.jpg'
            alt='sub-banner'
          />
        </div>
        <div className='sub-banner'>
          <div className='container'>
            <h1 className='text-center'> Live Match</h1>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb justify-content-center'>
                <li className='breadcrumb-item'>
                  <NavLink to='/'>Home</NavLink>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  Live Match
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section> */}
      <section className='float-start w-100 body-part pt-0'>
        <div className='blog-details-page comon-services-pge py-5'>
          <div className='container'>
            <div className='row gx-lg-5'>
              <div className='col-lg-7 col-xl-8'>
                {loading ? (
                  <Loader />
                ) : (
                  <div className='blog-post'>
                    <div className='live_blog_status'>
                      <div className='live_info_blinker'>
                        <div className='live_info_circle'></div>{' '}
                      </div>
                      {articleDetails?.status}
                    </div>

                    <h2 className='comon-heading mt-4'>
                      {articleDetails?.title}
                    </h2>
                    <div className='d-md-flex justify-content-between share-div'>
                      <ul className='list-unstyled d-flex'>
                        <li>
                          {' '}
                          <FaRegUser /> {articleDetails?.name}
                        </li>
                        <li>
                          {' '}
                          <LuCalendarDays style={{ marginRight: '5px' }} />
                          {dayjs(articleDetails?.created_at).format(
                            'MMM DD, YYYY'
                          )}
                        </li>
                      </ul>
                      {/* <NavLink to='#'>
                        {' '}
                        <CiShare2 /> Share
                      </NavLink> */}
                    </div>

                    <figure className='bigh-shou'>
                      <img
                        src={`${imgurl}${articleDetails?.desc_image}`}
                        alt={articleDetails?.title}
                      />
                    </figure>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: articleDetails?.description
                      }}
                    />
                  </div>
                )}
                {articleDetails?.sub_blog?.sort((a, b) => b.id - a.id).map((data, index) => (
                  <div className='SubBlog_main' key={index}>
                    <div>
                      <h4 className='time'>
                        {' '}
                        {dayjs(data?.created_at).format('MMM DD, YYYY')}
                      </h4>
                      <h4 className='time'>
                        {' '}
                        {dayjs(data?.created_at).format('hh:mm a')}
                      </h4>
                    </div>
                    <div className='SubBlog_main_inner'>
                      <h5 className='SubBlog_headings'>{data?.title}</h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data?.description
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className='col-lg-5 col-xl-4'>
                {/* <div className='category-div'>
                  <h2 className='comon-heading m-0'> Categories </h2>

                  <ul className='list-unstyled mt-4'>
                    {categoryData?.map((data, index) => (
                      <li key={index}>
                        <NavLink to={`/blogs/${data.name}`}>
                          <i className='fas fa-caret-right' /> {data.name}{' '}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div> */}
                {/* author div */}
                <div className='category-div'>
                  <figure>
                    {articleDetails?.image ? (
                      <img
                        alt={articleDetails?.name}
                        src={`${imgurl}${articleDetails?.image}`}
                      />
                    ) : (
                      <img
                        alt='Default Image'
                        src='https://cdn-icons-png.flaticon.com/128/3135/3135715.png'
                        className='img-fluid'
                        style={{ maxWidth: '50px' }}
                      />
                    )}
                  </figure>
                  <h6> {articleDetails?.name}</h6>
                  <p>
                    Updated:{' '}
                    {dayjs(articleDetails?.updated_at).format(
                      'MMM DD, YYYY, hh:mm a'
                    )}
                  </p>
                </div>
                {/* advertisement */}
                <div className='advertisement_div mt-2'>
                  {nativeBannerData &&
                    nativeBannerData?.slice(1, 2).map((data, index) => (
                      <div key={index}>
                        <NavLink to={data?.link} target='_blank'>
                          <figure>
                            <img alt={data?.link} src={data?.nativeImg} />
                          </figure>
                        </NavLink>
                      </div>
                    ))}
                </div>
                <div className='recent-post-div mt-5'>
                  <h2 className='mb-4 comon-heading'> Recent Live Blogs </h2>
                  <div className='recent-post-div-insiide'>
                    {LiveBlogData &&
                      LiveBlogData.slice(0, 8).map((data, index) => (
                        <NavLink
                          to={`/live-blog-details/${data?.slug}`}
                          className='d-flex w-100 justify-content-between align-items-center'
                          key={index}
                        >
                          <figure>
                            <img
                              alt={data?.title}
                              src={`${imgurl}${data?.desc_image}`}
                            />
                          </figure>
                          <h5>{data?.title}</h5>
                        </NavLink>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className=' mt-2 bottom_banner'>
                  {sponsersBannerData?.slice(5, 6).map((data, index) => (
                    <div key={index}>
                      <NavLink to={data?.link} target='_blank'>
                        <figure>
                          <img
                            alt={data?.link}
                            src={data?.bannerImg}
                            className='img-fluid'
                          />
                        </figure>
                      </NavLink>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LiveBlogdetail
