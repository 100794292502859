import React from 'react'

const Magicmoment = () => {
  return (
    <>
      <div className='mediasection d-inline-block w-100'>
        <div className='container'>
          <h2 className='main-hed01 text-light'>Magic Moments</h2>
          <div className='row row-cols-1 row-cols-lg-2 gy-4 mt-1'>
            <div className='col'>
              <a
                data-fancybox='wk'
                href='images/blo5.jpg'
                className='comon-links-divb05 bigo1-imgh'
              >
                <figure>
                  <img src='images/blo5.jpg' alt='pbnm' />
                  <figcaption>FGC CUP 2022</figcaption>
                </figure>
              </a>
            </div>
            <div className='col'>
              <div className='row row-cols-1 row-cols-sm-2 gy-4'>
                <div className='col'>
                  <a
                    data-fancybox='wk'
                    href='images/gla2.webp'
                    className='comon-links-divb05'
                  >
                    <figure>
                      <img src='images/gla2.webp' alt='pbnm' />
                      <figcaption>FGC CUP 2022</figcaption>
                    </figure>
                  </a>
                </div>
                <div className='col'>
                  <a
                    data-fancybox='wk'
                    href='images/gla3.webp'
                    className='comon-links-divb05'
                  >
                    <figure>
                      <img src='images/gla3.webp' alt='pbnm' />
                      <figcaption>FGC CUP 2022</figcaption>
                    </figure>
                  </a>
                </div>
                <div className='col'>
                  <a
                    data-fancybox='wk'
                    href='images/gla4.webp'
                    className='comon-links-divb05'
                  >
                    <figure>
                      <img src='images/gla4.webp' alt='pbnm' />
                      <figcaption>FGC CUP 2022</figcaption>
                    </figure>
                  </a>
                </div>
                <div className='col'>
                  <a
                    data-fancybox='wk'
                    href='images/gla5.jpg'
                    className='comon-links-divb05'
                  >
                    <figure>
                      <img src='images/gla5.jpg' alt='pbnm' />
                      <figcaption>FGC CUP 2022</figcaption>
                    </figure>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Magicmoment
