import React, { createContext, useState, useEffect } from 'react'

export const LiveBlogContext = createContext()

export const LiveBlogProvider = ({ children }) => {
  const [LiveBlogData, setLiveBlogData] = useState(null)

  const baseUrl = process.env.REACT_APP_API_BASE_URL

  const LiveMatchAPI = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }

    fetch(`${baseUrl}live-blog-list`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setLiveBlogData(result?.data?.data)
      })
      .catch(error => console.log('error', error))
  }

  useEffect(() => {
    LiveMatchAPI()
  }, [])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     LiveMatchAPI()
  //   }, 5000) // 10 seconds in milliseconds

  //   return () => clearInterval(interval)
  // }, [])
  return (
    <LiveBlogContext.Provider value={{ LiveBlogData, setLiveBlogData }}>
      {children}
    </LiveBlogContext.Provider>
  )
}
