import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaAngleDoubleRight } from "react-icons/fa";

const Upcomingmatch = ({deviceType}) => {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 3, // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1, // optional, default to 1.
        },
      };
  return (
   <>

<section className="ptb top-list-turnament">
        <div className="match-cover01">
          <div className="container">
          <div class="d-flex align-items-center justify-content-between">
            <h2 class="main-hed01">Upcoming Match</h2>
            <a href="#" class="btn btn-asee d-none d-md-inline-block">
              More Match <i class="fas fa-angle-right"></i>
            </a>
          </div>
            <Carousel
              responsive={responsive}
              swipeable={false}
              draggable={false}
              ssr={true}
              infinite={true}
            //   infiniteLoop={true}
              autoPlay={deviceType !== "mobile" ? true : false}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              customTransition="all .5s"
              transitionDuration={1500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={deviceType}
              dotListClass="custom-dot-list-style"
            //   itemClass="carousel-item-padding-40-px"
            >
              
              <div class="comon-matchbn">
              <a href="#" class="topikn-div">
                <div class="top-bg-backgrouh">
                  <figure>
                    <img src="images/st01.jpg" alt="pnhm" />
                  </figure>
                  <div class="conty">
                    <h4 class="text-center lega-text mt-0 mb-2">
                      United Leagues
                    </h4>
                    <span class="btn time-d05 d-table mx-auto">
                      21 Mar 2023
                    </span>
                    <h6 class="text-center text-white mb-3">
                      Sidney, North America
                    </h6>
                  </div>
                </div>

                <div class="row align-items-center mt-4 justify-content-center">
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r3.png" alt="club" />
                      </figure>
                      <h6 class="text-center">Spartams</h6>
                    </div>
                  </div>

                  <div class="col-2">
                    <h1 class="vs-text">VS</h1>
                  </div>
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r4.png" alt="club" />
                      </figure>

                      <h6 class="text-center">RedBull</h6>
                    </div>
                  </div>
                </div>
              </a>

              <div class="more-details-div text-center">
                <a href="#" class="btn btn-book-btn">
                  Book Ticket
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
             

              <div class="comon-matchbn">
              <a href="#" class="topikn-div">
                <div class="top-bg-backgrouh">
                  <figure>
                    <img src="images/st01.jpg" alt="pnhm" />
                  </figure>
                  <div class="conty">
                    <h4 class="text-center lega-text mt-0 mb-2">
                      United Leagues
                    </h4>
                    <span class="btn time-d05 d-table mx-auto">
                      21 Mar 2023
                    </span>
                    <h6 class="text-center text-white mb-3">
                      Sidney, North America
                    </h6>
                  </div>
                </div>

                <div class="row align-items-center mt-4 justify-content-center">
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r4.png" alt="club" />
                      </figure>
                      <h6 class="text-center">Spartams</h6>
                    </div>
                  </div>

                  <div class="col-2">
                    <h1 class="vs-text">VS</h1>
                  </div>
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r3.png" alt="club" />
                      </figure>

                      <h6 class="text-center">RedBull</h6>
                    </div>
                  </div>
                </div>
              </a>

              <div class="more-details-div text-center">
                <a href="#" class="btn btn-book-btn">
                  Book Ticket
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
             
               <div class="comon-matchbn">
              <a href="#" class="topikn-div">
                <div class="top-bg-backgrouh">
                  <figure>
                    <img src="images/st01.jpg" alt="pnhm" />
                  </figure>
                  <div class="conty">
                    <h4 class="text-center lega-text mt-0 mb-2">
                      United Leagues
                    </h4>
                    <span class="btn time-d05 d-table mx-auto">
                      21 Mar 2023
                    </span>
                    <h6 class="text-center text-white mb-3">
                      Sidney, North America
                    </h6>
                  </div>
                </div>

                <div class="row align-items-center mt-4 justify-content-center">
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r1.png" alt="club" />
                      </figure>
                      <h6 class="text-center">Spartams</h6>
                    </div>
                  </div>

                  <div class="col-2">
                    <h1 class="vs-text">VS</h1>
                  </div>
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r2.png" alt="club" />
                      </figure>

                      <h6 class="text-center">RedBull</h6>
                    </div>
                  </div>
                </div>
              </a>

              <div class="more-details-div text-center">
                <a href="#" class="btn btn-book-btn">
                  Book Ticket
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
             
               <div class="comon-matchbn">
              <a href="#" class="topikn-div">
                <div class="top-bg-backgrouh">
                  <figure>
                    <img src="images/st01.jpg" alt="pnhm" />
                  </figure>
                  <div class="conty">
                    <h4 class="text-center lega-text mt-0 mb-2">
                      United Leagues
                    </h4>
                    <span class="btn time-d05 d-table mx-auto">
                      21 Mar 2023
                    </span>
                    <h6 class="text-center text-white mb-3">
                      Sidney, North America
                    </h6>
                  </div>
                </div>

                <div class="row align-items-center justify-content-center">
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r1.png" alt="club" />
                      </figure>
                      <h6 class="text-center">Spartams</h6>
                    </div>
                  </div>

                  <div class="col-2">
                    <h1 class="vs-text">VS</h1>
                  </div>
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r3.png" alt="club" />
                      </figure>

                      <h6 class="text-center">RedBull</h6>
                    </div>
                  </div>
                </div>
              </a>

              <div class="more-details-div text-center">
                <a href="#" class="btn btn-book-btn">
                  Book Ticket
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
             
            </Carousel>
            
          {/* <a
            href="#"
            class="btn btn-asee text-dark d-table d-md-none mx-auto mt-4"
          >
            More Match <i class="fas fa-angle-right"></i>
          </a> */}
          </div>
        </div>
      </section>























   {/* ///////// */}
   {/* <div class="top-list-turnament">
        <div class="container">
          <div class="d-flex align-items-center justify-content-between">
            <h2 class="main-hed01">Upcoming Match</h2>
            <a href="#" class="btn btn-asee d-none d-md-inline-block">
              More Match <i class="fas fa-angle-right"></i>
            </a>
          </div>

          <div class="upcomin-matches owl-carousel owl-theme">
            <div class="comon-matchbn">
              <a href="#" class="topikn-div">
                <div class="top-bg-backgrouh">
                  <figure>
                    <img src="images/st01.jpg" alt="pnhm" />
                  </figure>
                  <div class="conty">
                    <h4 class="text-center lega-text mt-0 mb-2">
                      United Leagues
                    </h4>
                    <span class="btn time-d05 d-table mx-auto">
                      21 Mar 2023
                    </span>
                    <h6 class="text-center text-white mb-3">
                      Sidney, North America
                    </h6>
                  </div>
                </div>

                <div class="row align-items-center mt-4 justify-content-center">
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r3.png" alt="club" />
                      </figure>
                      <h6 class="text-center">Spartams</h6>
                    </div>
                  </div>

                  <div class="col-2">
                    <h1 class="vs-text">VS</h1>
                  </div>
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r4.png" alt="club" />
                      </figure>

                      <h6 class="text-center">RedBull</h6>
                    </div>
                  </div>
                </div>
              </a>

              <div class="more-details-div text-center">
                <a href="#" class="btn btn-book-btn">
                  Book Ticket
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>

            <div class="comon-matchbn">
              <a href="#" class="topikn-div">
                <div class="top-bg-backgrouh">
                  <figure>
                    <img src="images/st01.jpg" alt="pnhm" />
                  </figure>
                  <div class="conty">
                    <h4 class="text-center lega-text mt-0 mb-2">
                      United Leagues
                    </h4>
                    <span class="btn time-d05 d-table mx-auto">
                      21 Mar 2023
                    </span>
                    <h6 class="text-center text-white mb-3">
                      Sidney, North America
                    </h6>
                  </div>
                </div>

                <div class="row align-items-center mt-4 justify-content-center">
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r4.png" alt="club" />
                      </figure>
                      <h6 class="text-center">Spartams</h6>
                    </div>
                  </div>

                  <div class="col-2">
                    <h1 class="vs-text">VS</h1>
                  </div>
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r3.png" alt="club" />
                      </figure>

                      <h6 class="text-center">RedBull</h6>
                    </div>
                  </div>
                </div>
              </a>

              <div class="more-details-div text-center">
                <a href="#" class="btn btn-book-btn">
                  Book Ticket
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>

            <div class="comon-matchbn">
              <a href="#" class="topikn-div">
                <div class="top-bg-backgrouh">
                  <figure>
                    <img src="images/st01.jpg" alt="pnhm" />
                  </figure>
                  <div class="conty">
                    <h4 class="text-center lega-text mt-0 mb-2">
                      United Leagues
                    </h4>
                    <span class="btn time-d05 d-table mx-auto">
                      21 Mar 2023
                    </span>
                    <h6 class="text-center text-white mb-3">
                      Sidney, North America
                    </h6>
                  </div>
                </div>

                <div class="row align-items-center mt-4 justify-content-center">
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r1.png" alt="club" />
                      </figure>
                      <h6 class="text-center">Spartams</h6>
                    </div>
                  </div>

                  <div class="col-2">
                    <h1 class="vs-text">VS</h1>
                  </div>
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r2.png" alt="club" />
                      </figure>

                      <h6 class="text-center">RedBull</h6>
                    </div>
                  </div>
                </div>
              </a>

              <div class="more-details-div text-center">
                <a href="#" class="btn btn-book-btn">
                  Book Ticket
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>

            <div class="comon-matchbn">
              <a href="#" class="topikn-div">
                <div class="top-bg-backgrouh">
                  <figure>
                    <img src="images/st01.jpg" alt="pnhm" />
                  </figure>
                  <div class="conty">
                    <h4 class="text-center lega-text mt-0 mb-2">
                      United Leagues
                    </h4>
                    <span class="btn time-d05 d-table mx-auto">
                      21 Mar 2023
                    </span>
                    <h6 class="text-center text-white mb-3">
                      Sidney, North America
                    </h6>
                  </div>
                </div>

                <div class="row align-items-center justify-content-center">
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r1.png" alt="club" />
                      </figure>
                      <h6 class="text-center">Spartams</h6>
                    </div>
                  </div>

                  <div class="col-2">
                    <h1 class="vs-text">VS</h1>
                  </div>
                  <div class="col-5">
                    <div class="cul-div">
                      <figure class="">
                        <img src="images/r3.png" alt="club" />
                      </figure>

                      <h6 class="text-center">RedBull</h6>
                    </div>
                  </div>
                </div>
              </a>

              <div class="more-details-div text-center">
                <a href="#" class="btn btn-book-btn">
                  Book Ticket
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>

          <a
            href="#"
            class="btn btn-asee text-dark d-table d-md-none mx-auto mt-4"
          >
            More Match <i class="fas fa-angle-right"></i>
          </a>
        </div>
      </div> */}
   </>
  )
}

export default Upcomingmatch