import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Header from './component/Header'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/style.min.css'
import './assets/css/style.css'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import 'react-toastify/dist/ReactToastify.css'
import Footer from './component/Footer'
import Home from './component/Home'
import About from './component/About'
import Contact from './component/Contact'
import Blogdetail from './component/Blogdetail'
import Terms from './component/Terms'
import Policy from './component/Policy'
import { BlogDataProvider } from './component/BlogDataContext'
import { CategotyProvider } from './component/CategotyContext'
import CategotyBlogs from './component/CategotyBlogs'
import { SettingsProvider } from './component/SettingsContext'
import LiveMatch from './component/LiveMatch'
import { CMSProvider } from './component/CMSContext'
import LiveBlogdetail from './component/LiveBlogdetail'
import { GetBannerSponsersProvider } from './component/GetBannerSponsersContext'
import { LiveBlogProvider } from './component/LiveBlogContext'
import { GetNativeSponsersProvider } from './component/GetNativeSponsersContext'

function App () {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <BlogDataProvider>
        <LiveBlogProvider>
          <CategotyProvider>
            <SettingsProvider>
              <CMSProvider>
                <GetBannerSponsersProvider>
                  <GetNativeSponsersProvider>
                    <Header />
                    <Routes>
                      {/* <Route path='/' element={<Home />} /> */}
                      <Route path='/' element={<LiveMatch />} />
                      <Route path='/home' element={<Home />} />
                      <Route path='/about' element={<About />} />
                      <Route path='/contact' element={<Contact />} />
                      <Route path='/blog' element={<Blogdetail />} />
                      <Route path='/blog/:slug' element={<Blogdetail />} />
                      <Route path='/terms' element={<Terms />} />
                      <Route path='/policy' element={<Policy />} />
                      <Route path='/blogs/:slug' element={<CategotyBlogs />} />
                      <Route path='/live-match' element={<LiveMatch />} />
                      <Route
                        path='/live-blog-details/:slug'
                        element={<LiveBlogdetail />}
                      />
                    </Routes>
                    <Footer />
                  </GetNativeSponsersProvider>
                </GetBannerSponsersProvider>
              </CMSProvider>
            </SettingsProvider>
          </CategotyProvider>
        </LiveBlogProvider>
      </BlogDataProvider>
    </>
  )
}

export default App
