import React from 'react'
import Matchcoverage from './Matchcoverage'
import Latestnews from './Latestnews'
import TopArticles from './TopArticles'
import Upcomingmatch from './Upcomingmatch'
import Matchhighlight from './Matchhighlight'
import Featuresearies from './Featuresearies'
import Magicmoment from './Magicmoment'
import Hero from './Hero'

const Home = () => {
  return (
    <>
      {/* <Hero /> */}
      {/* <Matchcoverage /> */}
      <Latestnews />
      <TopArticles />
      <Upcomingmatch />
      <Matchhighlight />
      <Featuresearies />
      <Magicmoment />
    </>
  )
}

export default Home
