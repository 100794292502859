import React, { useContext } from 'react'
import { FaAngleDoubleRight } from 'react-icons/fa'
import { NavLink, useParams } from 'react-router-dom'
import { BlogDataContext } from './BlogDataContext'
import dayjs from 'dayjs'

const CategotyBlogs = () => {
  const { slug } = useParams()

  const { blogeData } = useContext(BlogDataContext)
  const imgurl = process.env.REACT_APP_API_IMG_URL
  const CategoryData = blogeData?.data?.data?.filter(
    blog => blog.category_name === `${slug}`
  )

  const stripHtmlTags = html => {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent || div.innerText || ''
  }

  if (!CategoryData || CategoryData.length === 0) {
    return (
      <>
        <section className='banner-part sub-main-banner float-start w-100'>
          <div className='baner-imghi'>
            <img
              src='https://oxentictemplates.in/templatemonster/cricket-new/images/sub-banner01.jpg'
              alt='sub-banner'
            />
          </div>
          <div className='sub-banner'>
            <div className='container'>
              <h1 className='text-center'>{slug}</h1>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb justify-content-center'>
                  <li className='breadcrumb-item'>
                    <a href='#'>Home</a>
                  </li>
                  <li className='breadcrumb-item active' aria-current='page'>
                    {slug}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </section>
        <section className='text-center bg-dark'>
          <div className='container no_data_available'>
            {' '}
            <h3 className='text-light  '>
              No blogs available for this category.
            </h3>
          </div>
        </section>
      </>
    )
  }

  return (
    <div>
      <section className='banner-part sub-main-banner float-start w-100'>
        <div className='baner-imghi'>
          <img
            src='https://oxentictemplates.in/templatemonster/cricket-new/images/sub-banner01.jpg'
            alt='sub-banner'
          />
        </div>
        <div className='sub-banner'>
          <div className='container'>
            <h1 className='text-center'>{slug}</h1>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb justify-content-center'>
                <li className='breadcrumb-item'>
                  <NavLink to='/'>Home</NavLink>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  {slug}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <div className='mt-3 mb-3 d-inline-block w-100 '>
        <div className='container category_data'>
          <div className='row mt-0 gy-5 g-lg-4'>
            <div className='col-lg-10'>
              {CategoryData && CategoryData.length > 0 && (
                <NavLink
                  to={`/blog/${CategoryData[0]?.slug}`}
                  className='comon-ret big-ret d-inline-block w-100 '
                >
                  <div className='imh-news position-relative'>
                    <img
                      alt='sty'
                      src={`${imgurl}${CategoryData[0]?.desc_image}`}
                    />
                  </div>
                  <div className='nes-text'>
                    <h5>{CategoryData[0]?.title}</h5>
                    <p>{stripHtmlTags(CategoryData[0]?.short_description)}</p>
                    <h6>
                      {' '}
                      <span>
                        {dayjs(CategoryData[0]?.created_at).format(
                          'MMM DD, YYYY'
                        )}
                      </span>{' '}
                      ||
                      <span> {CategoryData[0]?.name} </span>
                    </h6>
                  </div>
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </div>

      <section className='container'>
        <div className='row'>
          <div className='col-lg-8 col-xl-8 col-md-12'>
            <div className='recent-post-div mt-5'>
              <div className='recent-post-div-insiide'>
                {CategoryData?.slice(1)?.map((data, index) => (
                  <NavLink
                    to={`/blog/${data?.slug}`}
                    className='d-flex w-100  align-items-center'
                    key={index}
                  >
                    <figure>
                      <img
                        src={`${imgurl}${data?.desc_image}`}
                        alt={data.title}
                      />
                    </figure>
                    <div className=''>
                      <h5> {data.title}</h5>
                      <h6>
                        {' '}
                        <span>
                          {dayjs(data?.created_at).format('MMM DD, YYYY')}
                        </span>{' '}
                        ||
                        <span> {data?.name} </span>
                      </h6>
                    </div>
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default CategotyBlogs
